import { Injectable } from '@angular/core';
import { BrewerFilter } from '@hemro/lib/domain';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import { QueryTableDatasource, QueryTableDatasourceException } from '@yukawa/chain-base-angular-comp/query-table';
import { QueryResult } from '@yukawa/chain-base-angular-domain';
import { firstValueFrom, lastValueFrom, map } from 'rxjs';
import { ScaleTableEntry } from './scale-table-entry.model';
import { Scale } from './scale.entity';
import { Scale as IBrewer } from './scale.model';
import { ScaleService } from './scale.service';
import { DeviceRoles, UserService } from '@hemro/lib/profile';


@Injectable({
    providedIn: 'root',
})
export class ScaleDatasource extends QueryTableDatasource<Scale, IBrewer, ScaleTableEntry, BrewerFilter>
{
    companyColumnDisplayed: boolean = true;

    constructor()
    {
        super(ScaleTableEntry, {
            loadStore: true,
            loadBindings: true,
            type: 'scale'
        } as any);
    }

    public async read(filter?: BrewerFilter): Promise<Array<Scale>>
    {
        this.query = await firstValueFrom(AppInjector.get(ScaleService).queryBrewer({
            ...this.defaultFilter,
            ...filter,
        }).pipe(
            map(query => ({
                ...query,
                items: query.items.map(item => new Scale(item)),
            } as QueryResult<Scale>)),
        ));
        return this.query.items;
    }

    public async create(entry: ScaleTableEntry): Promise<ScaleTableEntry>
    {
        entry = this.newEntry(await lastValueFrom(AppInjector.get(ScaleService).create(entry.entity)));
        this.entries.splice(0, 0, entry);
        return await this.update(entry);
    }

    public async update(entry: ScaleTableEntry, brewer?: Partial<IBrewer>): Promise<ScaleTableEntry>
    {
        const index = this.entries.findIndex(item => item.id === entry.id);
        if (index === -1) {
            throw new QueryTableDatasourceException(`Device ${entry.id} does not exist.`);
        }

        if (brewer) {
            if (AppInjector.get(UserService).hasRole(...DeviceRoles.editDevices)) {
                entry = this.newEntry(await lastValueFrom(AppInjector.get(ScaleService).merge(new Scale({
                    ...brewer,
                    brewerId: entry.entity.brewerId,
                } as Scale))) as Scale);
            }
        }

        this.entries.splice(index, 1, entry);

        this.entryUpdated.emit(entry);

        return entry;
    }

    public async delete(entry: ScaleTableEntry): Promise<void>
    {
        const index = this.entries.findIndex(item => item.id === entry.id);
        if (index === -1) {
            throw new QueryTableDatasourceException(`Device ${entry.id} does not exist.`);
        }

        await lastValueFrom(AppInjector.get(ScaleService).delete(entry.entity));

        this.entries.splice(index, 1);

        this.entryDeleted.emit(entry);
    }

    public async getByID(brewerId: string): Promise<ScaleTableEntry>
    {
        return this.newEntry((await firstValueFrom(AppInjector.get(ScaleService).queryBrewer({
            ...this.defaultFilter,
            brewerId,
        }).pipe(
            map((query) =>
            {
                if (query.items.length === 0) {
                    throw new QueryTableDatasourceException(`Scale ${brewerId} does not exist.`);
                }
                return query.items[0];
            }),
        ))));
    }
}
