import { DeviceStatus, DeviceStatusEntity } from '@hemro/lib/domain';
import { CompanyRoles, HemroRoles, RegionRoles, StoreRoles, UserService } from '@hemro/lib/profile';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import {
    EntryDetailType,
    IQueryTableEntryDetail,
    ISelectOption,
    QueryTableEntry,
} from '@yukawa/chain-base-angular-comp/query-table';
import { Change } from '@yukawa/chain-base-angular-domain';
import { map } from 'rxjs';
import { PlainObject, StringKeys } from 'simplytyped';
import { Company } from '../company';
import { Region } from '../region';
import { Store, StoreService } from '../store';
import { ScaleDatasource } from './scale-datasource.service';
import { Scale } from './scale.entity';
import { Scale as IBrewer, SBrewerBinding } from './scale.model';


export class ScaleTableEntry extends QueryTableEntry<Scale, IBrewer>
{
    static viewConfig: IBrewer = {
        serial        : '',
        brewerId      : '',
        type          : '',
        name          : '',
        created       : {
            date : new Date(),
            notes: '',
            user : '',
        },
        change        : {
            date : new Date(),
            notes: '',
            user : '',
        },
        companyId     : 0,
        storeId       : 0,
        company       : {} as Company,
        region        : {} as Region,
        store         : {} as Store,
        bindings      : [],
    };

    public constructor(
        brewer: IBrewer = ScaleTableEntry.viewConfig,
    )
    {
        super(
            brewer instanceof Scale ? brewer : new Scale(brewer),
            brewer?.brewerId,
            brewer?.name,
        );
    }

    public get viewConfig(): IBrewer
    {
        return ScaleTableEntry.viewConfig;
    }

    protected override get labelTranslationPrefix(): string
    {
        return 'BREWER.';
    }

    public override init(): void
    {
        super.init();
    }

    protected override mapDetails<TKey = IBrewer>(
        details: Map<string, IQueryTableEntryDetail>,
        item: PlainObject,
        key: StringKeys<TKey>,
        detail: Partial<IQueryTableEntryDetail>,
    ): void
    {
        let type: EntryDetailType = 'text';
        let value         = item?.[key];
        const options = new Array<ISelectOption>();
        detail.entityName = 'Scale';
        const canEditAllCompanies = AppInjector.get(UserService).hasRole(HemroRoles.editAllCompanies);
        const companyColumnDisplayed = AppInjector.get(ScaleDatasource).companyColumnDisplayed;
        const userService = AppInjector.get(UserService);

        switch ((key as StringKeys<IBrewer> | StringKeys<DeviceStatusEntity> | StringKeys<DeviceStatus> | StringKeys<SBrewerBinding>)) {
            case 'change':
                this.mapDetails<Change>(details, value, 'date', {
                    ...detail,
                    group      : key,
                    showInTable: true,
                });
                return;
            case 'created':
                this.mapDetails<Change>(details, value, 'date', {
                    ...detail,
                    group      : key,
                    showInTable: true,
                });
                return;
            case 'name':
                const readonlyName = !userService.hasRole(...[
                    HemroRoles.admin,
                    HemroRoles.editDevices,
                    HemroRoles.editCompanyDevices,
                    RegionRoles.regionManager,
                    StoreRoles.storeManager
                ]);
                type                = 'text';
                detail.canEdit      = true;
                detail.readonly     = readonlyName;
                detail.required     = false;
                break;
            case 'region':
                type               = 'text';
                detail.showInTable = true;
                value              = item?.['store']?.region?.info?.name;
                break;
            case 'storeId':
                type                 = 'select';
                detail.showInDetails = false;
                detail.showInTable   = false;
                break;
            case 'company':
                type                 = 'text';
                value                = item?.['company']?.info?.name;
                detail.showInTable   = canEditAllCompanies && companyColumnDisplayed;
                break;
            case 'store':
                type                = 'select';
                detail.required     = true;
                detail.canEdit      = true;
                detail.readonly     = !userService.hasRole(...[HemroRoles.admin, CompanyRoles.companyAdmin ]);
                detail.groupByField = 'store.info.name';
                detail.label        = 'DEVICE.STORE.INFO.NAME';
                detail.compareWith  = (o1: Store | number, o2: Store | number): boolean =>
                {
                    const id1 = typeof o1 === 'number' ? o1 : o1?.storeId;
                    const id2 = typeof o2 === 'number' ? o2 : o2?.storeId;

                    return id1 === id2;
                };
                detail.options$     = AppInjector.get(StoreService).queryStore({
                    orderBy   : 'info.name',
                    orderDir  : 'ASC',
                    loadRegion: true,
                }).pipe(map(queryResult =>
                    queryResult.items.map(_item => ({
                        name : `${_item.info.name} (${_item.region.info.name})`,
                        value: _item,
                    }))));
                break;
            case 'brewerId':
                detail.showInTable = true;
                break;
            case 'companyId':
            case 'type':
            case 'cloudDate':
            case 'status':
            case 'serial':
                detail.showInTable = false;
                return;
            case 'bindings':
                this.mapDetails<SBrewerBinding>(details, value, 'grinderId', {
                    ...detail,
                    group      : key,
                    showInTable: true,
                });
                this.mapDetails<SBrewerBinding>(details, value, 'groupHead', {
                    ...detail,
                    group      : key,
                    showInTable: true,
                });
                return;
            case 'groupHead':
                type  = 'text';
                value = item[0]?.groupHead ?? ' ';
                detail.showInTable = true;
                detail.sortable = false;
                break;
            case 'grinderId':
                type  = 'text';
                value = item[0]?.grinderId ?? ' ';
                detail.showInTable = true;
                detail.sortable = false;
                break;
            default:
                super.mapDetails(details, item, key, detail);
                return;
        }

        let level = key;
        if (detail.group) {
            level = detail.group + '.' + key as never;
        }

        details.set(level, Object.assign(detail as Required<IQueryTableEntryDetail>, {
            key  : level,
            type,
            label: detail.label || this.formatKey(level),
            value,
            options,
        }));
    }
}
