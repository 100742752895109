import { UserInfo as IUserInfo } from '@hemro/lib/domain';
import { Enum } from '@yukawa/chain-base-angular-client';
import { Change, Owner, Person } from '@yukawa/chain-base-angular-domain';
import { Group, User as IUser } from '@yukawa/chain-main-angular-core';
import { Role as YukawaRole } from '@yukawa/chain-main-angular-session';
import { Account, GroupContext } from '@yukawa/chain-security-angular-core';


export interface User extends IUser
{
    account: Account;
    change: Required<Change>;
    created: Required<Change>;
    defaultOrgId: string;
    details: Record<string, object>;
    groupContexts: Required<GroupContext>[];
    owner: Required<Owner>;
    person: Required<Person>;
    userId: string;
    username: string;
}

export interface UserInfo extends IUserInfo
{
    /** @inheritDoc */
    change: Required<Change>;

    /** @inheritDoc */
    created: Required<Change>;

    /** @inheritDoc */
    firstName: string;

    /** @inheritDoc */
    groups: Array<Group>;

    /** @inheritDoc */
    lastName: string;

    /** @inheritDoc */
    userId: string;

    /** @inheritDoc */
    username: string;
}

export enum UserGroups
{
    /* eslint-disable @typescript-eslint/naming-convention */
    COMP_ADMINS             = 'Company Admin',
    REG_MAN                 = 'Region Manager',
    STORE_MAN               = 'Store Manager',
    BARISTA                 = 'Barista',
    /* eslint-enable @typescript-eslint/naming-convention */
}

export class HemroRoles extends YukawaRole
{

    /** edit all companies */
    static readonly editAllCompanies = HemroRoles.enum<HemroRoles>(0x2000, 'ROLE_ALL_COMP_E');
    /** edit MY company */
    static readonly editMyCompany    = HemroRoles.enum<HemroRoles>(0x4000, 'ROLE_MY_COMP_E');
    /** read all companies */
    static readonly readAllCompanies = HemroRoles.enum<HemroRoles>(0x8000, 'ROLE_ALL_COMP_R');

    /** edit ALL profiles */
    static readonly editAllProfiles      = HemroRoles.enum<HemroRoles>(0x10000, 'ROLE_ALL_PROF_E');
    /** edit company profiles */
    static readonly editCompanyProfiles  = HemroRoles.enum<HemroRoles>(0x20000, 'ROLE_COMP_PROF_E');
    /** read external profiles (customer,partner) */
    static readonly readExternalProfiles = HemroRoles.enum<HemroRoles>(0x40000, 'ROLE_EXT_PROF_R');
    /** edit external profiles (customer,partner) */
    static readonly editExternalProfiles = HemroRoles.enum<HemroRoles>(0x80000, 'ROLE_EXT_PROF_E');

    /** read all regions */
    static readonly readAllRegions     = HemroRoles.enum<HemroRoles>(0x100000, 'ROLE_ALL_REG_R');
    /** edit all regions */
    static readonly editAllRegions     = HemroRoles.enum<HemroRoles>(0x200000, 'ROLE_ALL_REG_E');
    /** edit company regions */
    static readonly editCompanyRegions = HemroRoles.enum<HemroRoles>(0x400000, 'ROLE_COMP_REG_E');
    /** read company regions */
    static readonly readCompanyRegions = HemroRoles.enum<HemroRoles>(0x800000, 'ROLE_COMP_REG_R');

    /** edit all stores */
    static readonly editAllStores     = HemroRoles.enum<HemroRoles>(0x1000000, 'ROLE_ALL_STORE_E');
    /** read all stores */
    static readonly readAllStores     = HemroRoles.enum<HemroRoles>(0x2000000, 'ROLE_ALL_STORE_R');
    /** edit company stores */
    static readonly editCompanyStores = HemroRoles.enum<HemroRoles>(0x4000000, 'ROLE_COMP_STORE_E');
    /** read company stores */
    static readonly readCompanyStores = HemroRoles.enum<HemroRoles>(0x8000000, 'ROLE_COMP_STORE_R');
    /** Edit store in managed region */
    static readonly editRegionStores  = HemroRoles.enum<HemroRoles>(0x10000000, 'ROLE_REG_STORE_E');

    /** assign store related groups */
    static readonly assignStoreGroups   = HemroRoles.enum<HemroRoles>(0x20000000, 'ROLE_GRP_STORE_A');
    /** assign Barista groups */
    static readonly assignBaristaGroups = HemroRoles.enum<HemroRoles>(0x40000000, 'ROLE_GRP_BARISTA_A');

    /** read all devices */
    static readonly readDevices        = HemroRoles.enum<HemroRoles>(0x80000000, 'ROLE_ALL_DEV_R');
    /** edit all devices */
    static readonly editDevices        = HemroRoles.enum<HemroRoles>(0x100000000, 'ROLE_ALL_DEV_E');
    /** edit company devices */
    static readonly editCompanyDevices = HemroRoles.enum<HemroRoles>(0x200000000, 'ROLE_COMP_DEV_E');

    /** edit test devices */
    static readonly editTestDevices = HemroRoles.enum<HemroRoles>(0x80000000000, 'ROLE_DEV_T_E');

    /** GrindCount Report (of company) */
    static readonly grindCountReport = HemroRoles.enum<HemroRoles>(0x400000000, 'ROLE_GC_REP');

    /** edit Firmware */
    static readonly editFirmware   = HemroRoles.enum<HemroRoles>(0x800000000, 'ROLE_FIRM_E');
    /** upload Firmware, can only upload and change firmware in status UPLOADED - can not change phase of the firmware */
    static readonly uploadFirmware = HemroRoles.enum<HemroRoles>(0x1000000000, 'ROLE_FIRM_U');
    /** edit scale firmware */
    static readonly editScaleFirmware = HemroRoles.enum<HemroRoles>(0x2000000000, 'ROLE_SC_FIRM_E');

    /** read events */
    static readonly readEvents = HemroRoles.enum<HemroRoles>(0x2000000000, 'ROLE_EVT_R');

    static readonly device = HemroRoles.enum<HemroRoles>(0x4000000000, 'ROLE_DEVICE');

    /** edit all charts */
    static readonly editAllCharts = HemroRoles.enum<HemroRoles>(0x8000000000, 'ROLE_ALL_CHART_E');
    /** read all charts */
    static readonly readAlCharts = HemroRoles.enum<HemroRoles>(0x10000000000, 'ROLE_ALL_CHART_R');
    /** edit company charts */
    static readonly editCompanyCharts    = HemroRoles.enum<HemroRoles>(0x20000000000, 'ROLE_COMP_CHART_E');
    /** read company charts */
    static readonly readCompanyCharts    = HemroRoles.enum<HemroRoles>(0x40000000000, 'ROLE_COMP_CHART_R');
    /** read company charts */
    static readonly editDeviceDistributor    = HemroRoles.enum<HemroRoles>(0x60000000000, 'ROLE_DEV_DIST_E');
}

export class CompanyRoles extends Enum<number>
{
    /** company management */
    static readonly companyAdmin = CompanyRoles.enum<CompanyRoles>(0x1, 'ROLE_COMP_ADMIN');

    /** Edit company for ROLE_ALL_COMP_E, ROLE_MY_COMP_E */
    static readonly editCompanies = [
        HemroRoles.editAllCompanies,
        HemroRoles.editMyCompany,
    ];

    /** Display company for ROLE_ALL_COMP_R, ROLE_ALL_COMP_E */
    static readonly displayCompanies = [
        ...CompanyRoles.editCompanies,
        CompanyRoles.companyAdmin,
        HemroRoles.readAllCompanies,
    ];
}

export class RegionRoles extends Enum<number>
{
    /** region management */
    static readonly regionManager = RegionRoles.enum<RegionRoles>(0x1, 'ROLE_REG_MAN');

    static readonly editRegions    = [
        HemroRoles.editAllRegions,
        HemroRoles.editCompanyRegions,
    ];
    static readonly displayRegions = [
        ...RegionRoles.editRegions,
        RegionRoles.regionManager,
        HemroRoles.readAllRegions,
        HemroRoles.readCompanyRegions,
    ];
}

export class StoreRoles extends Enum<number>
{
    /** store management */
    static readonly storeManager = StoreRoles.enum<StoreRoles>(0x1, 'ROLE_STORE_MAN');
    static readonly barista = StoreRoles.enum<DeviceRoles>(0x2, 'ROLE_BARISTA');

    static readonly editStores = [
        HemroRoles.editAllStores,
        HemroRoles.editCompanyStores,
        HemroRoles.editRegionStores,
        RegionRoles.regionManager,
    ];

    static readonly displayStores = [
        ...StoreRoles.editStores,
        StoreRoles.storeManager,
        HemroRoles.readAllStores,
        HemroRoles.readCompanyStores,
    ];

    static readonly createEditStores = [
        HemroRoles.admin,
        HemroRoles.readAllStores,
        HemroRoles.editAllStores,
        HemroRoles.readCompanyStores,
        HemroRoles.editCompanyStores,
        HemroRoles.assignStoreGroups,
        HemroRoles.assignBaristaGroups,
        HemroRoles.editRegionStores,
    ];

    static readonly viewStoreDetails = [
        HemroRoles.admin,
        HemroRoles.editAllStores,
        HemroRoles.editCompanyStores,
        HemroRoles.editRegionStores,
        HemroRoles.assignStoreGroups,
        HemroRoles.assignBaristaGroups,
    ];

    static readonly editStoreDetails = [
        HemroRoles.admin,
        HemroRoles.editAllStores,
        HemroRoles.editCompanyStores,
        HemroRoles.editRegionStores,
    ];
}

export class DeviceRoles extends Enum<number>
{
    static readonly editDevices = [
        HemroRoles.editDevices,
        HemroRoles.editCompanyDevices,
        RegionRoles.regionManager,
        StoreRoles.storeManager,
    ];

    static readonly displayDevices = [
        ...DeviceRoles.editDevices,
        HemroRoles.readDevices,
    ];
}

export class ProfileRoles extends Enum<number>
{
    static readonly readInternalInvitations = ProfileRoles.enum<ProfileRoles>(0x1, 'ROLE_INT_INV_R');
    static readonly writeAllInvitations = ProfileRoles.enum<ProfileRoles>(0x2, 'ROLE_ALL_INV_W');

    /** Edit profiles for ROLE_ADMIN, ROLE_ALL_PROF_E, ROLE_EXT_PROF_E, ROLE_COMP_PROF_E */
    static readonly editProfiles = [
        HemroRoles.editAllProfiles,
        HemroRoles.editCompanyProfiles,
        HemroRoles.editExternalProfiles,
    ];

    /** Display profile companies and filter + column only for
     *  ROLE_ADMIN, ROLE_ALL_PROF_E, ROLE_EXT_PROF_R, ROLE_EXT_PROF_E */
    static readonly displayProfileCompanies = [
        HemroRoles.editAllProfiles,
        HemroRoles.readExternalProfiles,
        HemroRoles.editExternalProfiles,
    ];

    /** Display profiles for
     *  ROLE_ADMIN, ROLE_ALL_PROF_E, ROLE_EXT_PROF_R, ROLE_EXT_PROF_E, ROLE_COMP_PROF_E */
    static readonly displayProfiles = [
        ...ProfileRoles.editProfiles,
        HemroRoles.readExternalProfiles,
        StoreRoles.storeManager,
        RegionRoles.regionManager,
    ];

    /** ROLE_ADMIN, ROLE_ALL_PROF_E, ROLE_COMP_PROF_E, ROLE_EXT_PROF_E */
    static readonly inviteUsers = [
        HemroRoles.admin,
        HemroRoles.editAllProfiles,
        HemroRoles.editCompanyProfiles,
        HemroRoles.editExternalProfiles,
    ];
}

export class RecipeRoles extends Enum<number>
{
    static readonly editThreshold = StoreRoles.enum<RecipeRoles>(0x1, 'ROLE_REC_THR_E');
    static readonly readThreshold = StoreRoles.enum<RecipeRoles>(0x2, 'ROLE_REC_THR_R');

    static readonly displayRecipes = [
        RecipeRoles.editThreshold,
        RecipeRoles.readThreshold,
    ];
}
