import { FirmwarePhase, Firmware as IFirmware, FirmwareFilter as IFirmwareFilter } from '@hemro/lib/domain';
import { Change } from '@yukawa/chain-base-angular-domain';

export enum FirmwareType {
    GBS = 'GBS',
    SCALE = 'SCALE',
}

export interface FirmwareFilter extends IFirmwareFilter
{
    type?: FirmwareType;
}

export interface Firmware extends IFirmware
{
    change: Required<Change>;
    created: Required<Change>;

    /** @inheritDoc */
    downloadUrl: string;

    /** @inheritDoc */
    nextVersion?: string;

    /** @inheritDoc */
    notes?: string;

    /** @inheritDoc */
    phase: FirmwarePhase;

    /** @inheritDoc */
    version: number;

    /** @inheritDoc */
    versionText: string;

    type?: FirmwareType;
}
