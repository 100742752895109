import { Validators } from '@angular/forms';
import { FirmwarePhase } from '@hemro/lib/domain';
import { HemroRoles, UserService } from '@hemro/lib/profile';
import { TranslocoService } from '@ngneat/transloco';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import {
    EntryDetailType,
    IQueryTableEntryDetail,
    ISelectOption,
    QueryTableEntry,
} from '@yukawa/chain-base-angular-comp/query-table';
import { Version } from '@yukawa/chain-base-angular-comp/shared';
import { Change } from '@yukawa/chain-base-angular-domain';
import { SessionService } from '@yukawa/chain-main-angular-session';
import { of } from 'rxjs';
import { PlainObject, StringKeys } from 'simplytyped';
import { firmwareAutocompleteSearch } from './firmware-autocomplete-search';
import { Firmware, FirmwareType } from './firmware.model';


export const versionValidator = Validators.pattern(new RegExp(
    '^(?<major>\\d{1,3})\\.(?<minor>\\d{1,3})\\.(?<patch>\\d{1,4})$'));

export class FirmwareTableEntry extends QueryTableEntry<Firmware>
{
    static viewConfig: Firmware = {
        version    : 0,
        versionText: '',
        nextVersion: '',
        phase      : FirmwarePhase.UPLOADED,
        notes      : '',
        downloadUrl: '',
        type: FirmwareType.GBS,
        created: {
            date : new Date(),
            notes: '',
            user : '',
        },
        change : {
            date : new Date(),
            notes: '',
            user : '',
        },
    };

    public constructor(
        firmware: Firmware = FirmwareTableEntry.viewConfig,
        public readonly file?: File,
    )
    {
        super(firmware, firmware?.version, firmware?.versionText);

        if (firmware.created) {
            firmware.created.date = new Date(firmware.created.date);
        }
        if (firmware.change) {
            firmware.change.date = new Date(firmware.change.date);
        }

        if (!firmware.notes) {
            firmware.notes = '';
        }

        if (!firmware.nextVersion) {
            firmware.nextVersion = null as never;
        }
        else {
            const version = Version.tryParse(firmware.nextVersion);
            if (version) {
                firmware.nextVersion = version.toString();
            }
        }

        if (!firmware.phase) {
            firmware.phase = FirmwarePhase.UPLOADED;
        }
    }

    public get viewConfig(): Firmware
    {
        return FirmwareTableEntry.viewConfig;
    }

    protected override get labelTranslationPrefix(): string
    {
        return 'FIRMWARE.';
    }

    public override init(): void
    {
        super.init();
    }

    protected override mapDetails<TKey = Firmware>(
        details: Map<string, IQueryTableEntryDetail>,
        item: PlainObject,
        key: StringKeys<TKey>,
        detail: Partial<IQueryTableEntryDetail>,
    ): void
    {
        let type: EntryDetailType;
        let value         = item?.[key];
        const options     = new Array<ISelectOption>();
        detail.entityName = 'Firmware';

        switch ((key as StringKeys<Firmware>)) {
            case 'change':
                this.mapDetails<Change>(details, value, 'date', {
                    ...detail,
                    group: key,
                });
                return;
            case 'created':
                this.mapDetails<Change>(details, value, 'date', {
                    ...detail,
                    group: key,
                });
                return;
            case 'downloadUrl':
                type               = 'url';
                detail.required    = true;
                detail.showInTable = false;
                detail.validators  = [
                    Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-=?&]*/?'),
                ];
                break;
            case 'nextVersion':
                type = 'version';
                if (this.id === '0') {
                    return;
                }
                detail.canEdit            = true;
                detail.validators         = [versionValidator];
                detail.autocompleteSearch = firmwareAutocompleteSearch(item as Firmware, detail);
                detail.showInTable        = false;
                break;
            case 'notes':
                type               = 'multiline';
                detail.canEdit     = true;
                detail.showInTable = false;
                detail.rows    = 10;
                break;
            case 'type':
                type                 = 'text';
                detail.showInDetails = false;
                detail.showInTable   = false;
                break;
            case 'phase':
                type            = 'select';
                detail.required = true;
                detail.canEdit = AppInjector.get(SessionService).session?.user?.hasRole(HemroRoles.admin)
                    || !AppInjector.get(UserService).hasRole(HemroRoles.uploadFirmware);
                const phases = this.getAvailablePhases(value)
                detail.options$ = of(phases.map(_item => ({
                    name : AppInjector.get(TranslocoService).translate(`FIRMWARE.PHASE_NAME.${_item}`),
                    value: _item,
                })));
                value           = AppInjector.get(TranslocoService).translate(`FIRMWARE.PHASE_NAME.${value}`);
                break;
            case 'version':
                return;
            case 'versionText':
                type              = 'version';
                detail.required   = true;
                detail.validators = [versionValidator];
                if (value === '') {
                    value          = '0.0.1';
                    detail.canEdit = true;
                }
                else {
                    value          = this.name;
                    detail.canEdit = false;
                }
                break;
            default:
                super.mapDetails(details, item, key, detail);
                return;
        }

        let level = key;
        if (detail.group) {
            level = detail.group + '.' + key as never;
        }

        details.set(level, Object.assign(detail as Required<IQueryTableEntryDetail>, {
            key  : level,
            type,
            label: this.formatKey(level),
            value,
            options,
        }));
    }

    getAvailablePhases(currentValue: FirmwarePhase) {
        switch (currentValue) {
            case FirmwarePhase.OBSOLETE:
            case FirmwarePhase.RELEASED:
                return [FirmwarePhase.RELEASED, FirmwarePhase.OBSOLETE];
            default:
                return Object.values(FirmwarePhase)
        }
    }
}
