import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BrewerFilter } from '@hemro/lib/domain';
import { ConfigService, RestAspect } from '@yukawa/chain-base-angular-client';
import { QueryResult } from '@yukawa/chain-base-angular-domain';
import { Observable } from 'rxjs';
import { Scale } from './scale.entity';


@Injectable({ providedIn: 'root' })
export class ScaleService extends RestAspect
{
    constructor(
        http: HttpClient,
        configService: ConfigService,
    )
    {
        super(http, configService, configService.formatUrl('brewerUrl'));
    }

    loadByKey(deviceId: string): Observable<Scale>
    {
        return this.http.get<Scale>(`${this.formatServiceUrl()}/?key=${deviceId}`);
    }

    delete(device: Scale): Observable<Scale>
    {
        return this.http.delete<Scale>(`${this.formatServiceUrl()}/delete?brewerId=` + device.brewerId);
    }

    create(device: Scale): Observable<Scale>
    {
        return this.http.post<Scale>(this.formatServiceUrl('/create'), device.toJson());
    }

    merge(device: Scale): Observable<Scale>
    {
        return this.http.post<Scale>(this.formatServiceUrl('/merge'), device.toJson());
    }

    put(device: Scale): Observable<Scale>
    {
        return this.http.post<Scale>(this.formatServiceUrl('/put'), device.toJson());
    }

    queryBrewer(filter: BrewerFilter): Observable<QueryResult<Scale>>
    {
        return super.query<Scale>(this.formatServiceUrl('/query'), filter);
    }

    update(device: Scale): Observable<Scale>
    {
        return this.http.post<Scale>(this.formatServiceUrl('/update'), device.toJson());
    }
}
